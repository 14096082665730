:root {
  --width: 100vw;
  --full-width: 100vw;

  @media (min-width: 42em) {
    --width: 42rem;
  }

  --angle: -11deg;
  --magic-number: 0.09719;
  --skew-padding: calc(var(--width) * var(--magic-number));
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}

// Brand Style One
.brand-carousel-activation {
  padding: 0 50px;
  @media #{$sm-layout} {
    padding: 0 10px;
  }
  li {
    margin-top: 0;
    margin-bottom: 0;
    a {
      display: block;
      img {
        opacity: 0.8;
        transition: 0.4s;
      }
    }
    &:hover {
      a {
        img {
          opacity: 1;
          filter: contrast(0.5);
        }
      }
    }
  }
  &.rainbow-slick-arrow button.slick-arrow {
    width: 50px;
    height: 50px;
  }
  &.rainbow-slick-arrow button.slick-arrow i {
    display: block;
    font-size: 24px;
    color: #fff;
  }
  &.rainbow-slick-arrow button.slick-arrow::before {
    display: none;
  }
}

// .rainbow-brand-area {
//   .row {
//     padding: 20px 0px;
//   }
// }

.brand-style-1 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    flex-basis: 25%;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    margin: 0px 0px -2px -2px;
    border-color: var(--color-border);
    padding: 40px 0 40px 0;
    text-align: center;

    @media #{$large-mobile} {
      flex-basis: 33.33%;
    }

    @media #{$small-mobile} {
      flex-basis: 50%;
    }

    a {
      display: block;
      img {
        opacity: 0.8;
        transition: 0.4s;
      }
    }

    &:hover {
      a {
        img {
          opacity: 1;
          filter: contrast(0.5);
        }
      }
    }
  }
}
.btn-see-more {
  display: flex;
  margin-top: 10px;

  .header-btn {
    margin-left: 25px;
    @media #{$sm-layout} {
      display: none;
    }
  }
}

.main-logos-wrapper {
  display: flex;
  justify-content: center;
  width: 80%;
  img {
    width: 100%;
    display: block;
  }

  @media #{$sm-layout} {
    display: flex;
    justify-content: end;
    width: 100%;
    img {
      width: 48%;
      display: block;
    }
  }
}

// .partners-wrapper {
//   .clip-path {
//     position: relative;
//     margin-top: calc((var(--clip-padding) * -1) - 2px);
//     background-size: 0.5em 0.5em, 100% 100%;
//     padding: calc(
//         (var(--clip-padding) * 2) - (var(--clip-padding) - var(--skew-padding))
//       )
//       0 4em;
//     clip-path: polygon(
//       0% calc(var(--clip-padding) * 2),
//       100% 0%,
//       100% 100%,
//       0% 100%
//     );
//     -webkit-clip-path: polygon(
//       0% calc(var(--clip-padding) * 2),
//       100% 0%,
//       100% 100%,
//       0% 100%
//     );

//     .content {
//       max-width: 250px;
//       margin: 0 auto;
//       padding: 1.5em;
//       position: relative;
//     }
//   }
// }

.expand-logos-wrapper {
  width: 80%;
  // max-width: 1000px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media #{$sm-layout} {
    width: 100%;
  }
}

// Brand Style Two
.brand-style-2 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    flex-basis: 16.666%;
    text-align: center;

    @media #{$md-layout} {
      flex-basis: 25%;
    }

    @media #{$sm-layout} {
      flex-basis: 25%;
    }

    @media #{$large-mobile} {
      flex-basis: 33.33%;
    }

    @media #{$small-mobile} {
      flex-basis: 50%;
    }

    a {
      display: block;

      img {
        opacity: 0.6;
        transition: 0.3s;
        max-height: 70px;
      }
    }

    &:hover {
      a {
        img {
          opacity: 1;
        }
      }
    }
  }

  &.variation-2 {
    li {
      flex-basis: 25%;
      text-align: center;

      @media #{$sm-layout} {
        flex-basis: 33.33%;
      }

      @media #{$small-mobile} {
        flex-basis: 50%;
      }
    }
  }
}

.advance-brand {
  @extend %liststyle;
}
