.vision-card,
.mission-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .mission-img,
  .vision-img {
    width: 100%;
    // max-width: 550px;
    margin: 0 auto;
  }

  .mission-img img,
  .vision-img img {
    // width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
  }
}

.team-desc {
  font-size: 24px;
}

.image {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.team-card {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .role-title {
    font-size: 18px;
  }

  img {
    width: 200px;
    height: 200px;
  }
}

.profile-container {
  display: flex;
  justify-content: center;

  .profile-card {
    max-width: 300px;
    margin: 10px;
    text-align: center;
  }

  .person-name {
    font-weight: Bold;
    color: var(--color-black);
    font-size: var(--h5);
  }
}
