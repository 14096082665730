.developers-cover {
  position: relative;
  flex: 1;
  .title {
    position: absolute;
    top: 45%;
    color: var(--color-white);
    z-index: 1;
    font-size: 46;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image-wrapper img {
    width: 100%;
    max-width: 100%;
    height: 500px;
    object-fit: cover;
  }
}

.cover-api {
  position: relative;
  flex: 1;
  .title {
    position: absolute;
    top: 45%;
    // transform: translate(-50%, -50%);
    color: var(--color-darker);
    z-index: 1;
  }
  .apis-button {
    position: absolute;
    top: 45%;
    right: 0%;
    transform: translate(-50%, 0%);
    z-index: 1;

    a {
      color: var(--color-white);
    }
  }
}

.apps-title {
  text-align: center;
  color: var(--color-blue);
  margin-top: 20px;
}

.apps-wrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
  // display: grid;
  // grid-template-columns: repeat(2, 4fr);
  // gap: 40px;

  .apps-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    max-width: 300px;
    margin: 20px;
  }

  .apps-card img {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
  }
}

.developers-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .subtitle {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin: 20px 0px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-light);
    box-shadow: var(--shadow-lighter);
    &.radius-rounded {
      border-radius: 100px;
    }
  }

  .resource-title {
    font-size: 36px;
  }
}

.table-wrapper.table {
  border-collapse: collapse;
  width: 100%;
}

.table-wrapper.table thead tr th {
  background: var(--color-blue) !important;
  color: var(--color-white) !important;
  padding: 10px;
}

.table-wrapper.table tbody tr th {
  background: var(--color-blue) !important;
  color: var(--color-white) !important;
}

.resources {
  .img-resource {
    // width: 100%;
    // max-width: 550px;
    // margin: 0 auto;

    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }

  .developer-row {
    display: flex;
  }

  .developer-col {
    display: flex;
    flex-grow: 1;
    position: relative;

    @media #{$sm-layout} {
      margin: auto;
    }
  }

  .app-col {
    position: relative;
  }

  .img-resource img {
    // width: 100%;
    // height: auto;
    // display: block;
    // max-width: 100%;

    // position: absolute;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .content-resource {
    padding: 15px 10px;

    .title {
      color: var(--color-blue);
    }
    .desc-resource {
      font-size: var(--font-size-b1);
    }

    .button-group {
      position: absolute;
      bottom: 20px;
      // left: 50%;
      // transform: translateY(-50%);
    }
  }

  .app-wrapper {
    display: flex;
    justify-content: center;
  }
}
