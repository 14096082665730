.news-cover-section {
  position: relative;
  flex: 1;
  .title {
    position: absolute;
    top: 45%;
    color: var(--color-darker);
    z-index: 1;
  }
}

.news-wrapper {
  margin: 60px auto;

  .news-reverse-wrapper {
    flex-direction: row-reverse;

    @media #{$md-layout} {
      flex-direction: row;
    }
  }

  .news-card-wrapper,
  .news-reverse-wrapper {
    display: flex;
    margin: 20px;

    .news-image {
      width: 100%;
      max-width: 550px;
      margin: 0 auto;
    }

    .news-image img {
      width: 100%;
      height: 380px;
      display: block;
      max-width: 100%;
      border-radius: 20px;

      @media #{$sm-layout} {
        height: 280px;
      }
    }

    .news-content {
      margin: 20px 0px;
      .title {
        font-size: 35px;
        color: var(--color-blue);
        font-family: var(--font-mono);
      }

      .see-more {
        display: flex;
        align-items: center;
        margin: 20px 0px;

        .seemore-btn {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 1px solid var(--color-blue);
          &:hover {
            border: 2px solid var(--color-blue);
            box-shadow: 0 0 25px 10px rgba(112, 100, 233, 0.1);
          }

          @media #{$md-layout} {
            width: 50px;
            height: 50px;
          }

          @media #{$sm-layout} {
            width: 50px;
            height: 50px;
          }
        }

        .seemore-txt {
          font-size: 25px;
          margin-left: 20px;
          color: var(--color-darker);
        }
      }
    }
  }
}
