.contactus-image {
  display: flex;
}

.cover-section {
  position: relative;
  flex: 1;
  .title {
    position: absolute;
    top: 45%;
    //   transform: translate(-50%, -50%);
    color: var(--color-darker);
    z-index: 1;
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-wrapper img {
  width: 100%;
  max-width: 100%;
  height: 500px;
  object-fit: cover;
}

.address-contact {
  font-size: 18px;
}

.contact-form-wrapper {
  margin-top: 60px;

  .responsive-form {
    margin: 0px auto;

    .form-group {
      margin-bottom: 1rem;

      label {
        display: block;
        color: var(--color-darker);
        margin-bottom: 0.5rem;
      }

      input[type="text"],
      input[type="email"],
      textarea {
        margin-right: 10px;
        width: 100%;
        padding: 0.5rem;
        border-radius: 4px;
        border: none;
        border-bottom: 1px solid var(--color-midgray);
      }
    }

    .button-group {
      margin-bottom: 10px;
    }

    button {
      background-color: var(--color-blue);
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      max-width: 90%;
    }
  }
}
