.pagination-container {
  display: flex;
  justify-content: space-between;

  li {
    list-style: none;
  }

  .paging-list {
    display: flex;

    @media #{$sm-layout} {
      display: none;
    }

    .page-item {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin: 0 3px;
      text-align: center;
    }

    .page-link {
      border-radius: 50%;
    }

    .page-item:hover,
    .page-item:focus {
      border-radius: 50%;
    }
  }

  .page-link {
    color: #666;
    padding: 0.65rem 0.95rem;
    height: 40px;

    .prev-page,
    .next-page {
      display: flex;

      @media #{$sm-layout} {
        p {
          margin: 0px !important;
        }

        img {
          display: none;
        }
      }

      img {
        height: 20px !important;
        width: 30px !important;
        margin-top: 5px !important;
      }

      .previous-arrow {
        transform: scaleX(-1);
      }
    }
  }

  .page-link:hover,
  .page-link:focus {
    color: #444;
    background-color: #eee;
    border-color: #dee2e6;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #2c353f;
    border-color: #2c353f;
    border-radius: 50%;
  }
}
